#content {
	will-change: padding;
	display: flex;
	flex-grow: 1;
	position: relative;
	height: 100%;

	&:not(.content-body-column) {
		flex-direction: column;
		overflow-y: auto;
		padding: 0 1rem 1rem;

		@include media-breakpoint-up(xl) {
			padding-left: $sidebar-width + 1rem;
		}
	}

	&.content-body-column {
		gap: 0.5rem;
	}
}

.sidebar-min {
	#content {
		@include media-breakpoint-up(xl) {
			padding-left: $sidebar-min-bar-width;
		}
	}
}

.content-body {
	flex: 1;
	overflow-y: auto;
	height: 100%;
	padding: 0 1rem 1rem;
}

.content-body-column {
	display: flex;
	align-items: start;
	overflow: hidden;
	padding: 0;
}

.content-search {
	border: 1px solid $input-border-color;
	border-radius: $border-radius-pill;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	max-width: 350px;
	width: 100%;
	padding: 0 1rem 0 0.85rem;
	transition: border-color 300ms, box-shadow 300ms;

	.form-control {
		border: 0;
		background-color: transparent;
		padding-inline: 0.5rem;
		height: 2.25rem;

		&:focus {
			box-shadow: none;
		}

		@include placeholder {
			color: var(--#{$prefix}body-color);
		}
	}

	&:focus-within {
		box-shadow: 0 0 0 0.2rem var(--#{$prefix}theme-200);
		border-color: $input-focus-border-color
	}
}

.content-header {
    height: 3rem;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: var(--#{$prefix}body-bg);
}

.content-sidebar {
	width: $sidebar-width;
	flex-shrink: 0;
	height: 100%;
}

// Switch blocks in mobile
// e.g Contacts, Email etc.
@include media-breakpoint-down(md) {
	[data-qa-block] {
		will-change: transform;
		transition: transform 300ms;
		width: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
	}

	[data-qa-block="body"] {
		transform: translate3d(105%, 0, 0);
	}

	.content-body-toggled {
		[data-qa-block="list"] {
			transform: translate3d(-100%, 0, 0);
		}

		[data-qa-block="body"] {
			transform: translate3d(0, 0, 0);
		}
	}
}
