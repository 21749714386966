// Icon wrapper
.icon {
	min-width: 2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	display: inline-flex;
	transition:
		color 300ms,
		background-color 300ms,
		box-shadow 300ms;
	color: var(--#{$prefix}emphasis-color);

	&.ph,
	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.15rem;
		}
	}

	&:hover,
	&.show {
		background: var(--#{$prefix}theme-200);
	}
}

// Links
.link-bordered {
	border: 1px solid var(--#{$prefix}theme-300);
	border-radius: $border-radius;
	padding: 0 0.65rem;
	height: 1.875rem;
	font-size: $font-size-sm;
	color: var(--#{$prefix}body-color);
	display: flex;
	align-items: center;
	transition:
		background-color 300ms;
	line-height: 1;

	&:hover {
		border-color: var(--#{$prefix}theme-300);
		background-color: var(--#{$prefix}theme-50);
	}

	& > .ph {
		font-size: 1rem;
	}
}

.link-body {
	color: var(--#{prefix}body-color);

	&:hover {
		color: var(--#{$prefix}emphasis-color);
	}
}

// Masonry layout
.masonry {
	column-count: 3;

	@include media-breakpoint-down(xxl) {
		column-count: 2;
	}

	@include media-breakpoint-down(lg) {
		column-count: 1;
	}

	.card {
		display: inline-block;
		width: 100%;
		margin-bottom: 0.75rem;
	}
}

// Custom scrollbar
.scrollbar {
	scrollbar-gutter: $scrollbar-width;
	scrollbar-color: #B8C4CE #f8fafc;
	scrollbar-width: thin;
	overflow: auto;

	::-webkit-scrollbar {
		width: $scrollbar-width;
	}
	
	::-webkit-scrollbar-track {
		background: transparent;
	}
	
	::-webkit-scrollbar-thumb {
		background: #B8C4CE;
		border-radius: $border-radius;
		border: 1px solid $body-bg;
	}
	
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}
}

// Backdrop
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	transition: opacity 500ms;
	z-index: $sidebar-z-index - 1;
}

// Action
.bg-highlight {
	background-color: var(--#{$prefix}component-accent-bg);
}

.bg-active {
	background: var(--#{$prefix}theme-200);
}

.bg-hover {
	&:hover {
		background-color: var(--#{$prefix}component-accent-bg);
	}
}

.bg-hover-emphasis {
	&:hover {
		background-color: var(--#{$prefix}theme-100);
	}
}

// Page loader
#page-loader {
	position: fixed;
	inset: 0;
	z-index: 100;
	background-color: var(--#{$prefix}body-bg);
	overflow: hidden;
	display: flex;
	align-items: start;
	font-size: 0.35rem;
	transition: opacity 300ms;

	.placeholder {
		background: var(--#{$prefix}theme-500);
	}

	.spinner-border {
		color:  var(--#{$prefix}theme-500);
	}
}
