.breadcrumb-item {
	& > a {
		color: var(--#{$prefix}secondary-color);

		&:hover {
			color: var(--#{$prefix}body-color);
		
		}
	}

	& + .breadcrumb-item {
		&:before {
			font-family: $font-family-icon-light;
			font-size: 0.5rem;
			margin-top: 0.225rem;
		}
	}
}
