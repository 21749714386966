.header {
	height: $header-height;
	z-index: $sidebar-z-index + 1;
	gap: 0.5rem;
	padding: 0 1rem;
	display: flex;
	align-items: center;
	background-color: $card-bg;
	border-bottom: 1px solid $card-border-color;
	flex-shrink: 0;
}

.header-menu {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	list-style: none;
	margin: 0;
	gap: 1rem;
	padding: 0;

	& > li {
		& > button {
			border: 0;
			background-color: transparent;
			color: var(--#{$prefix}emphasis-color);
			font-size: $font-size-2xl;
			min-width: 2.25rem;
			min-height: 2.25rem;
			border-radius: $border-radius;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover,
			&.show {
				background: var(--#{$prefix}theme-200);
				background: var(--#{$prefix}theme-200);
			}

			
		}

		.dropdown-menu {
			&.header-dropdown-menu {
				left: auto;
				top: $header-height - 0.5rem;
				right: 2.75rem;
				position: fixed;
				height: calc(100% - $header-height);
				max-width: 21rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				opacity: 0;
				pointer-events: none;
				transform: translate3d(0, 0, 0);
				transition: opacity 300ms, transform 300ms;
				margin-top: 0;

				&.show {
					transform: translate3d(-1rem, 0, 0);
					opacity: 1;
					pointer-events: auto;
				}

				@include media-breakpoint-down(sm) {
					max-width: calc(100% - 1.5rem);
					height: calc(100% - 4.5rem);
					right: 0.75rem;
					top: 2.25rem;

					&.show {
						transform: translate3d(0, -1.5rem, 0);
					}
				}
			}
		}
	}
}

.header-notify {
	position: relative;
	isolation: isolate;

	&:after {
		will-change: transform, border-color;
		content: "";
		position: absolute;
		width: 2.1875rem;
		height: 2.1875rem;
		border-radius: 50%;
		border: 2px solid transparent;
		z-index: 1;
		inset: 0;
		margin: auto;
		transform: scale3d(0, 0, 0);
		animation-iteration-count: infinite;
		animation-duration: 2000ms;
		backface-visibility: hidden;
		animation-name: notifyPulse;
		pointer-events: none;
	}
}

// Color mode
#color-mode-toggle {
	display: grid;
	place-items: center;
	overflow: hidden;
	position: relative;

	.ph {
		position: absolute;
		transform: scale(0);
		opacity: 0;
		transition: transform 300ms, opacity 300ms;
	}
}

[data-bs-theme="light"] {
	#color-mode-toggle {
		.ph-moon {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

[data-bs-theme="dark"] {
	#color-mode-toggle {
		.ph-sun {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

// Top navigation
@keyframes notifyPulse {
	0% {
		transform: scale3d(0, 0, 0);
		border-color: transparent;
	}

	50% {
		border-color: rgba($red, 0.75);
	}

	to {
		transform: scale3d(1, 1, 1);
		border-color: transparent;
	}
}