#sidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: $sidebar-width;
	height: 100%;
	z-index: $sidebar-z-index;
	transition: transform 300ms, width 300ms, opacity 300ms, background-color 300ms;
	will-change: transform, width;
	padding-top: $header-height + 0.5rem;

	@include media-breakpoint-down(xl) {
		background-color: $card-bg;
		border-right: 1px solid $card-border-color;
		transform: translate3d(-100%, 0, 0);

		&.toggled {
			transform: translate3d(0, 0, 0);

			#sidebar-close {
				opacity: 1 !important;
				pointer-events: auto;
			}
		}
	}
}

.sidebar-close {
	position: absolute;
	right: 0;
	top: 0;
	margin-top: 0.5rem;
	margin-right: -2.5rem;
	opacity: 0;
	transition: opacity 200ms;
	pointer-events: none;
}

.sidebar-min {
	#sidebar {
		[data-simplebar] {
			.simplebar-scrollbar {
				display: none !important;
			}
		}

		@include media-breakpoint-up(xl) {
			width: $sidebar-min-bar-width;
			border-right: 1px solid var(--#{$prefix}theme-200);

			&:hover {
				width: $sidebar-width;
				background-color: $card-bg;
				border-radius: 0 $border-radius-xl $border-radius-xl 0;
			}

			&:not(:hover) {
				.menu-title {
					color: transparent;

					&:before {
						opacity: 1;
					}
				}

				.menu {
					& > li {
						& > a {
							& > span {
								opacity: 0;
							}
						}
					}
				}

				.menu-sub {
					& > a {
						&:after {
							opacity: 0;
						}
					}

					& > ul {
						display: none !important;
					}
				}
			}
		}
	}
}

// Menu
.menu {
	flex: 1 1 auto;
	height: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	& > li {
		& > a {
			color: var(--#{$prefix}emphasis-color);
			display: flex;
			align-items: center;
			padding: $nav-link-padding-y $nav-link-padding-x;
			transition: color 300ms, background-color 300ms, border-color 300ms;
			position: relative;
			font-weight: $font-weight-medium;
			border-radius: $border-radius-pill;

			& > .ph {
				font-size: 1.1rem;
				margin: 0 0.7rem 0 -0.1rem;
				flex-shrink: 0;
			}

			& > span {
				opacity: 1;
				transition: opacity 300ms;
			}
		}

		&:not(.active):not(.menu-title) {
			&:hover {
				& > a {
					background-color: var(--#{$prefix}theme-200);
				}
			}
		}

		&.active {
			& > a {
				color: $blue;
			}

			& > ul {
				display: block;
			}
		}

		&.opened {
			& > a {
				&:after {
					transform: rotate(90deg);
				}

				&:before {
					opacity: 1;
					transform: scale(1);
				}
			}

			& > ul {
				margin-bottom: 0.5rem;
			}
		}
	}
}

.menu > li,
.menu-sub > ul > li {
	& > a {
		white-space: nowrap;
	}
}

.menu-title {
	font-size: $font-size-sm;
	white-space: nowrap;
	line-height: 100%;
	position: relative;
	margin: 0 0 0.5rem 0.7rem;
	color: var(--#{$prefix}secondary-color);
	transition: color 300ms;

	&:not(:first-child) {
		margin-top: 1.5rem;
	}

	@include media-breakpoint-up(xl) {
		&:before {
			content: "...";
			position: absolute;
			left: 0;
			bottom: 0.2rem;
			font-size: 1.25rem;
			line-height: 100%;
			opacity: 0;
			transition: opacity 300ms;
			color: $body-secondary-color;
		}
	}
}

.menu-sub {
	& > a {
		position: relative;

		&:after {
			opacity: 1;
			@include font-icon("\e13a", $font-size-xs);
			position: absolute;
			right: 0.65rem;
			top: 0.6rem;
			transition: transform 300ms, opacity 300ms;
			pointer-events: none;
			font-weight: $font-weight-bold;
			-webkit-text-fill-color: currentColor;
		}
	}

	& > ul {
		padding: 0 0.8rem;
		list-style: none;
		overflow: hidden;
		border-radius: 0 0 $border-radius-lg $border-radius-lg;
		display: none;
		margin: 0 1rem;
		will-change: height, opacity;
		transition: height 300ms, opacity 400ms;

		& > li {
			& > a {
				font-size: $font-size-sm;
				display: block;
				padding: 0.25rem 0.7rem;
				color: var(--#{$prefix}emphasis-color);
				transition: background-color 300ms;
				border-radius: $border-radius-pill;

				&:not(.active) {
					&:hover {
						background-color: var(--#{$prefix}theme-200);
					}
				}

				&.active {
					color: $blue;
				}
			}

			&:first-child {
				padding-top: 0.5rem;
			}

			&:last-child {
				padding-bottom: 0.5rem;
			}
		}
	}
}

// Sidebar list
// Secondary sidebar in 2 and 3 column layouts
// e.g contacts, messages, file manager, etc
#sidebar-secondary {
	@include media-breakpoint-down(xl) {
		will-change: transform, opacity;
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		z-index: $sidebar-z-index + 2;
		background-color: $card-bg;
		border-right: 1px solid $card-border-color;
		transform: translate3d(-100%, 0, 0);
		transition: transform 300ms, opacity 300ms;
		opacity: 0;

		&.toggled {
			transform: translate3d(0, 0, 0);
			opacity: 1;

			.sidebar-close {
				opacity: 1 !important;
				pointer-events: auto;
			}
		}

		.content-header {
			background-color: $card-bg;
		}
	}
}

// Logo
#logo {
	circle {
		fill: var(--#{$prefix}logo-circle-fill);
	}

	rect {
		fill: var(--#{$prefix}logo-rect-fill);
	}
}