* {
	box-sizing: border-box;
}

body {
	height: 100vh;
	height: 100svh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}